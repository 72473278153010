import { Portfolio } from "../models/portfolio.model";
import { Slugs } from "../enums/slugs.enum";

export const getPortfolioLinkFromSlug = (slug: string) => {
  return `/${slug}/`;
};

export const getPortfolioLink = ({ frontmatter: { slug } }: Portfolio) => {
  return getPortfolioLinkFromSlug(slug);
};

export const getPackagesLinkWithoutSlash = (slug: string, location: string) => {
  return `/${slug}-${location}-${Slugs.PORTFOLIO_PACKAGES_SUFFIX}`;
};

export const getPackagesLink = (slug: string, location: string) => {
  return `${getPackagesLinkWithoutSlash(slug, location)}/`;
};

export const getBlogCategoryLink = (category: string) => {
  return `/${Slugs.BLOG_PREFIX}/${category}/`;
};

export const getBlogSubCategoryLink = (
  category: string,
  subCategory: string
) => {
  return `/${Slugs.BLOG_PREFIX}/${category}/${subCategory}/`;
};

export const getBlogLink = (blogSlug: string) => {
  return `/${Slugs.BLOG_PREFIX}/${blogSlug}/`;
};

export const getFooterPackagesLink = (portfolio: Portfolio) => {
  const locationSlug = portfolio.frontmatter.packages![0].location_slug;

  return getPackagesLink(portfolio.frontmatter.slug, locationSlug);
};

export const getTagLinkFromSlug = (slug: string) => {
  return `/${Slugs.TAG_PREFIX}/${slug}/`;
};

export const getTestimonialLink = (slug: string) => {
  return `${slug}/`;
};

export const getPortfolioGroupLink = (slug: string) => {
  return `/${Slugs.PORTFOLIO_GROUP_PREFIX}/${slug}/`;
};

export const getImagePreviewLink = (
  portfolioSlug: string,
  imageIndex: number,
  imageTitle: string
) => {
  return `/${portfolioSlug}/${imageIndex}/${imageTitle}/`;
};
