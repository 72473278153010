import React from "react";
import cx from "classnames";
import styles from "../styles/commons/imagePreview.module.scss";
import Overlay from "../components/v2/overlay/Overlay";
import CloseIcon from "../images/icons/cross-icon.inline.svg";
import SVGContainer from "../components/commons/svg-container/SVGContainer";
import { Link, navigate } from "gatsby";
import {
  getPortfolioLinkFromSlug,
  getImagePreviewLink,
} from "../utils/link.helper";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import V2Entry from "../components/v2/v2-entry";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImagePreviewTemplate = (props: any) => {
  const {
    leftUrl,
    rightUrl,
    url,
    label,
    index,
    total,
    portfolioSlug,
  } = props.pageContext;

  const enabledBackward = (className?: string) => (
    <Link
      to={getImagePreviewLink(portfolioSlug, index - 1, leftUrl)}
      className={cx(styles.arrow, styles.arrow__normal, className ?? "")}
    >
      <IoIosArrowBack />
    </Link>
  );

  const disabledBackward = (className?: string) => (
    <div className={cx(styles.arrow, styles.arrow__disabled, className ?? "")}>
      <IoIosArrowBack />
    </div>
  );

  const enabledForward = (className?: string) => (
    <Link
      to={getImagePreviewLink(portfolioSlug, index + 1, rightUrl)}
      className={cx(styles.arrow, styles.arrow__normal, className ?? "")}
    >
      <IoIosArrowForward />
    </Link>
  );

  const disabledForward = (className?: string) => (
    <div className={cx(styles.arrow, styles.arrow__disabled, className ?? "")}>
      <IoIosArrowForward />
    </div>
  );

  return (
    <V2Entry>
      <div className={styles.imagePreview}>
        <Overlay className={styles.overlay}>
          <div className={cx("column", styles.container)}>
            {leftUrl
              ? enabledBackward(styles.arrow__desktop)
              : disabledBackward(styles.arrow__desktop)}
            <div className={styles.content}>
              <div className={styles.content__mobile}>
                <TransformWrapper>
                  <TransformComponent>
                    <img src={url} alt={label ?? portfolioSlug} />
                  </TransformComponent>
                </TransformWrapper>
              </div>
              <div className={styles.content__desktop}>
                <img src={url} alt={label ?? portfolioSlug} />
              </div>
            </div>
            {rightUrl
              ? enabledForward(styles.arrow__desktop)
              : disabledForward(styles.arrow__desktop)}
            <button
              onClick={() => {
                navigate(getPortfolioLinkFromSlug(portfolioSlug), {
                  state: {
                    index: props.location.state.index,
                  },
                });
              }}
              className={styles.close}
            >
              <SVGContainer>
                <CloseIcon />
              </SVGContainer>
            </button>
            <div className={styles.arrow__mobile}>
              {leftUrl ? enabledBackward() : disabledBackward()}
              {rightUrl ? enabledForward() : disabledForward()}
            </div>
          </div>
        </Overlay>
      </div>
    </V2Entry>
  );
};

export default ImagePreviewTemplate;
