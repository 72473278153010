import React, { RefObject } from "react";
import withAppContext from "../../../contexts/app.context";
import AppWrapper from "../../commons/app-wrapper";

interface Props {
  children: React.ReactNode;
  handleScroll?: Function;
  reference?: RefObject<HTMLDivElement>;
}

const V2Entry: React.FC<Props> = (props: Props) => {
  if (process.env.GATSBY_VERSION === "v2") {
    //CSS Imports
    require("../../../styles/v2/index.scss");
    require("../../../styles/v2/fonts-new.scss");
    require("../../../styles/v2/global.scss");
  }

  return (
    <AppWrapper reference={props.reference} handleScroll={props.handleScroll}>
      {props.children}
    </AppWrapper>
  );
};

export default withAppContext(V2Entry);
